import { TrackingEvent } from '../../types';

export interface GoogleEventData {
    action: string;
    category: string;
}

export interface GoogleEventPayload {
    ExtraData: string;
    action: string;
}

// migrate from GA3
const customisedGA4Events: Partial<Record<TrackingEvent, GoogleEventData>> = {
    Website: {
        category: 'Website Click',
        action: 'click',
    },
    Share: {
        category: 'Share Click',
        action: '',
    },
    EmailReveal: {
        category: 'Email Click',
        action: 'reveal',
    },
    PhoneReveal: {
        category: 'Phone Click',
        action: 'reveal',
    },
    PhoneSubmit: {
        category: 'Phone Click',
        action: 'submit',
    },
    LocationReveal: {
        category: 'Location Click',
        action: 'reveal',
    },
    MobileReveal: {
        category: 'Mobile Click',
        action: 'reveal',
    },
    SaveReveal: {
        category: 'Save Click',
        action: 'reveal',
    },
    EnquiryReveal: {
        category: 'Enquiry Click',
        action: 'reveal',
    },
    EnquirySubmit: {
        category: 'Enquiry Click',
        action: 'submit',
    },
    SaveSubmit: {
        category: 'Save Click',
        action: 'submit',
    },
    DownloadsReveal: {
        category: 'Downloads Click',
        action: 'reveal',
    },
    DownloadsClick: {
        category: 'Downloads Click',
        action: 'submit',
    },
    SocialMediaClick: {
        category: 'Social media click',
        action: 'reveal',
    },
    AddToCart: {
        category: 'AddToCart',
        action: 'ap_add_to_cart',
    },
    Purchase: {
        category: 'Purchase',
        action: 'ap_purchase',
    },
    ViewSearchResults: {
        category: 'ViewSearchResults',
        action: 'view_search_results',
    },
    Ad_Serve: {
        category: 'ad_event',
        action: 'ad_serve',
    },
    Ad_Impression: {
        category: 'ad_event',
        action: 'ad_impression',
    },
    Ad_CTAClick: {
        category: 'ad_event',
        action: 'ad_cta_click',
    },
    Ad_ImageClick: {
        category: 'ad_event',
        action: 'ad_image_click',
    },
};

// normalize the GA4 event name according to the official suggestion.
// 'ClickEvent' => 'click_event'
// 'Click Event' => 'click_event'
export function normalizeGA4EventName(name: string): string {
    return name
        .replace(/([a-z])([A-Z])/g, '$1_$2') // convert camelCase to snake_case
        .toLowerCase()
        .replace(/\s+/, '_')
        .replace(/_+/, '_'); // remove duplicated underscores
}

// Compose GA4 event data. Migrated from GA3.
// event naming suggestions from:
// https://www.bounteous.com/insights/2021/01/28/event-naming-considerations-google-analytics-4-properties
export function getGA4EventData(
    event: TrackingEvent,
    data: GoogleEventPayload | null = null
): GoogleEventData {
    let eventData: GoogleEventData;

    if (event in customisedGA4Events) {
        eventData = customisedGA4Events[event] as GoogleEventData;
        if (event === 'Website') {
            eventData.action = 'click';
        } else if (event === 'Share') {
            if (data?.ExtraData) {
                eventData.action = normalizeGA4EventName(data.ExtraData);
            }
        }
    } else {
        eventData = {
            category: event,
            action: data?.action ? normalizeGA4EventName(data.action) : '',
        };
    }

    if (!eventData.action || eventData.action.length === 0) {
        eventData.action = 'tracker_event';
    }

    return eventData;
}
